<template>
 <div class="bg-white rounded-md shadow-sm p-6 mt-4 w-full">
    <div class="mb-2">
        <p  class="font-bold text-base">
            Información del {{ derecho.tipo === 1 ? 'derecho de peticion' : derecho.tipo === 2 ? 'requerimiento' : 'PQR'}}
        </p>
    </div>
    <div class="mt-2">
        <div class="flex justify-between my-3">
        <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" style="padding: 3px;" class="h-5 w-5 text-blue-600 rounded-full mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path class="" stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            <p class="text-sm text-gray-500 font-medium">
            Asunto
            </p>
        </div>
        <div class="flex justify-end">
            <p class="text-sm text-black font-medium">
            {{ derecho.asunto }}
            </p>
        </div>
        </div>
        <div class="flex justify-between my-3">
        <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" style="padding: 3px;" class="h-5 w-5 text-blue-600 rounded-full mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
            </svg>
            <p class="text-sm text-gray-500 font-medium">
            Fecha de notificación
            </p>
        </div>
        <div class="flex justify-end">
            <p class="text-sm text-black font-medium">
            {{$h.formatDate(derecho.fechaNotificacion,'YYYY-MM-DD')}}
            </p>
        </div>
        </div>
        <div class="flex justify-between my-3">
        <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" style="padding: 3px;" class="h-5 w-5 text-blue-600 rounded-full mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
            </svg>
            <p class="text-sm text-gray-500 font-medium">
            Fecha de vencimiento
            </p>
        </div>
        <div class="flex justify-end">
            <p class="text-sm text-black font-medium">
            {{$h.formatDate(derecho.fechaVencimiento,'YYYY-MM-DD')}}
            </p>
        </div>
        </div>
    </div>
</div>
</template>
<script>
import derechosPeticionStore from '../../../../../../../store/derechosPeticion.store'
import { computed } from 'vue'

export default {
  name: 'derechoInfo',
  setup () {
    const derecho = computed(() => {
      return derechosPeticionStore.getters._derecho
    })
    return {
      derecho
    }
  }
}
</script>
<style scoped>

</style>
